import axios from 'axios';
import configs from '../configs'

const sendEmail = async ( data, token) => {
  const headers = {
      'app-authorization': `${token}`
  }

  try {
      const result = await axios
      .post(
          `${configs.novaAPI.BASE_URL}/email/send`,
          data,
          { headers }
      )

      return result.data.data
  } catch(e) {
      console.log(e)
  }
}

export default {
  sendEmail
}